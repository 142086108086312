<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return">
					<el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
				</p>
				<div class="h-b">
					<el-button type="primary" size="small" @click="addExternalAccess"
						v-right-code="'Sysexternalaccess:Edit'">新增</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="saveExternalAccess"
						v-right-code="'Sysexternalaccess:Edit'">保存</el-button>
				</div>
			</div>
		</div>

		<el-tabs type="border-card" v-model="activeName" @tab-click="tab1Click">
			<el-tab-pane name="tabbasic">
				<span slot="label"><i class="el-icon-search"></i> 基本信息</span>
				<div class="form-list">
					<el-form :model="dataSource" ref="_externalAccessForm" :rules="externalAccessCheckRule">
						<el-form-item>
							<el-col :span="3" class="form-title"><span style="color:red;">*</span>系统类型：</el-col>
							<el-col :span="7">
								<el-form-item prop="ExternalSystemType">
									<el-select v-model="dataSource.ExternalSystemType" filterable
										@change="externalTypeChange(dataSource.ExternalSystemType)"
										placeholder="--请选择系统类型--">
										<el-option v-for="item in options" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="3" class="form-title"><span style="color:red;">*</span>外部系统：</el-col>
							<el-col :span="7">
								<el-form-item prop="ExternalSystemID">
									<el-select v-model="dataSource.ExternalSystemID" filterable
										@change="externalChange(dataSource.ExternalSystemID)" placeholder="--请选择系统--">
										<el-option v-for="item in dataSource.ExternalSystemList"
											:key="item.ExternalSystemID" :label="item.ExternalSystemName"
											:value="item.ExternalSystemID">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-form-item>
						<el-form-item>
							<!-- <el-col :span="3" class="form-title"><span style="color:red;">*</span>客户：</el-col>
							<el-col :span="7">
								<el-form-item prop="OptEnterpriseID">
									<el-select v-model="dataSource.OptEnterpriseID" filterable @change="custChange(dataSource.OptEnterpriseID)"
									 placeholder="--请选择客户--">
										<el-option v-for="item in dataSource.EnterpriseInfoList" :key="item.OptEnterpriseID" :label="item.EnterpriseName"
										 :value="item.OptEnterpriseID">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col> -->
							<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主（可多选）：</el-col>
							<el-col :span="7">
								<el-form-item prop="CustomerCodes">
									<el-select v-model="dataSource.CustomerCodeList" filterable multiple collapse-tags
										:maxlength="512" placeholder="--请选择货主--">
										<el-option v-for="item in dataSource.LoadCustomerList" :key="item.CustomerCode"
											:label="item.CustomerName" :value="item.CustomerCode">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<div v-if="dataSource.ExternalSystemType == 200 || dataSource.ExternalSystemType == 300">
								<el-col :span="3" class="form-title"><span style="color:red;"
										v-if="dataSource.ExternalSystemType == 200">*</span>仓储商/承运商：</el-col>
								<el-col :span="7">
									<el-form-item prop="MemberId">
										<el-select v-model="dataSource.MemberId"
											:clearable="dataSource.ExternalSystemType == 300"
											@change="memberChange(dataSource.MemberId)" filterable
											placeholder="--请选择仓储服务商/承运商--">
											<el-option v-for="item in dataSource.MemberList" :key="item.MemberId"
												:label="item.MemberName" :value="item.MemberId">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</div>
						</el-form-item>
						<el-form-item>
							<el-col :span="3" class="form-title"><span style="color:red;">*</span>状态：</el-col>
							<el-col :span="3">
								<el-radio-group v-model="dataSource.ExternalAccessStatus">
									<el-radio :label="'100'">启用</el-radio>
									<el-radio :label="'200'">停用</el-radio>
								</el-radio-group>
							</el-col>
							<div v-if="dataSource.ExternalSystemType == 100">
								<el-col :span="3" class="form-title">允许更新商品信息：</el-col>
								<el-col :span="1">
									<el-switch v-model="dataSource.IsAllowUpdateProduct" active-color="#13ce66"
										inactive-color="#ff4949">
									</el-switch>
								</el-col>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</el-tab-pane>
		</el-tabs>

		<el-tabs type="border-card" v-model="tabActiveName" @tab-click="tab2Click">
			<el-tab-pane name="tabcomparam">
				<span slot="label"><i class="el-icon-date"></i> 参数信息</span>
				<div v-if="tabActiveName === 'tabcomparam'" class="form-padding">
					<div class="h-handle-button">
						<div class="h-b">
							<el-button type="primary" size="small" @click="addParam">添加参数</el-button>
						</div>
					</div>
					<el-table :data="dataSource.ParamList" border highlight-current-row>
						<el-table-column prop="ParamDesc" label="描述">
							<template slot-scope="scope">
								<el-input v-model="scope.row.ParamDesc" size="mini" placeholder="描述" />
							</template>
						</el-table-column>

						<el-table-column prop="ParamKey" label="参数键">
							<template slot-scope="scope">
								<el-input v-model="scope.row.ParamKey" size="mini" placeholder="参数键" />
							</template>
						</el-table-column>

						<el-table-column prop="ParamValue" label="参数值">
							<template slot-scope="scope">
								<el-input v-model="scope.row.ParamValue" size="mini" placeholder="参数值" />
							</template>
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="120">
							<template slot-scope="scope">
								<el-button @click="removeParam(scope.row)" size="small" type="text">移除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-tab-pane>

			<el-tab-pane name="tabcommap">
				<span slot="label"><i class="el-icon-date"></i> 映射信息</span>
				<div v-if="tabActiveName === 'tabcommap'" class="form-padding">
					<div class="h-handle-button">
						<div class="h-b">
							<el-button type="primary" size="small" @click="addMap">添加映射</el-button>
						</div>
					</div>
					<el-table :data="dataSource.ExternalMappingList" border highlight-current-row>
						<el-table-column prop="MappingKey" label="映射键">
							<template slot-scope="scope">
								<el-select v-model="scope.row.MappingKey" filterable size="mini" placeholder="--请选择映射键--">
									<el-option v-for="item in dataSource.MapKeyList" :key="item.MappingKey"
										:label="item.MappingKeyName" :value="item.MappingKey">
									</el-option>
								</el-select>
							</template>
						</el-table-column>

						<el-table-column prop="ExternalValueDesc" label="外部系统值描述">
							<template slot-scope="scope">
								<el-input v-model="scope.row.ExternalValueDesc" size="mini" placeholder="外部系统值描述" />
							</template>
						</el-table-column>

						<el-table-column prop="ExternalValue" label="外部系统值">
							<template slot-scope="scope">
								<el-input v-model="scope.row.ExternalValue" size="mini" placeholder="外部系统值" />
							</template>
						</el-table-column>

						<el-table-column prop="LocalValueDesc" label="本系统值描述">
							<template slot-scope="scope">
								<el-input v-model="scope.row.LocalValueDesc" size="mini" placeholder="本系统值描述" />
							</template>
						</el-table-column>

						<el-table-column prop="LocalValue" label="本系统值">
							<template slot-scope="scope">
								<el-input v-model="scope.row.LocalValue" size="mini" placeholder="本系统值" />
							</template>
						</el-table-column>

						<el-table-column fixed="right" label="操作" width="120">
							<template slot-scope="scope">
								<el-button @click="removeMap(scope.row)" size="small" type="text">移除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
export default {
	data() {
		var _this = this;
		var checkExternalSystemID = function (rule, value, callback) {
			if (!value) return callback(new Error('请选择系统'));
			callback();
		};
		var checkOptEnterpriseID = function (rule, value, callback) {
			if (!value) return callback(new Error('请选择客户'));
			callback();
		};
		var checkExternalSystemType = function (rule, value, callback) {
			if (!value) return callback(new Error('请选择系统类型'));
			callback();
		};
		//var checkCustomerCodes = function (rule, value, callback) {
		//    if (!value) return callback(new Error('请选择货主'));
		//    callback();
		//};
		return {
			activeName: 'tabbasic',
			tabActiveName: 'tabcomparam',
			multipleSelection: [],
			externalSystemList: [],
			customerList: [],
			projectList: [],
			isDisabled: false,
			externalAccessCheckRule: {
				ExternalSystemID: [{
					validator: checkExternalSystemID,
					trigger: 'blur'
				}],
				OptEnterpriseID: [{
					validator: checkOptEnterpriseID,
					trigger: 'blur'
				}],
				ExternalSystemType: [{
					validator: checkExternalSystemType,
					trigger: 'blur'
				}],
			},
			options: [{
				value: "100",
				label: '下单系统'
			}, {
				value: "200",
				label: '仓储系统'
			}, {
				value: "300",
				label: '配送系统'
			}, {
				value: "400",
				label: '其他'
			}],
		}
	},
	props: {
		config: {
			isDetailDisplay: false
		},
		dataSource: {
		},
	},
	computed: {

	},
	watch: {
		dataSource: {
			handler(curVal, oldVal) {
				var _this = this;
				this.isDisabled = this.dataSource.AccessID == null || this.dataSource.AccessID == 0;
				_this.dataSource.LoadCustomerList = [];
				if (curVal.AccessID != oldVal.AccessID||!curVal.AccessID) {
					_this.$ajax.send("omsapi/sysexternalaccess/getcustbyenterprisecode", "get", {
					}, (data) => {
						console.log(_this.dataSource.LoadCustomerList)
						_this.dataSource.LoadCustomerList = data.Result;
					});
				}
			},
			deep: true
		}
	},

	mounted() {
		var _this = this;
		this.Event.$on("clearEditExternalAccessForm", () => this.resetForm);
		//this.getOwnerList();
	},

	methods: {
		addExternalAccess() {
			this.Event.$emit("onAddExternalAccess");
		},
		tab1Click: function (tab, event) { },
		tab2Click: function (tab, event) { },
		resetForm() {
			!this.isPassValidate && this.$refs['_externalAccessForm'].resetFields(); //清空表单
		},
		externalTypeChange: function (val) {
			if (val == '' || val == null) return;
			var _this = this;
			if (_this.isDisabled) {
				_this.dataSource.OptEnterpriseID = null;
				_this.dataSource.EnterpriseCode = null;
				_this.dataSource.EnterpriseName = null;
				_this.dataSource.ExternalSystemID = null;
				_this.dataSource.ExternalIdentity = null;
				_this.dataSource.ExternalSystemName = null;
				_this.dataSource.ExternalSystemList = [];
				_this.dataSource.MemberId = null;
				_this.dataSource.MemberCode = null;
				_this.dataSource.MemberName = null;
				_this.dataSource.MemberList = [];
				_this.$ajax.send("omsapi/sysexternalaccess/getsystemlistbytype", "get", {
					type: val
				}, (data) => {
					if (data.Result != null)
						_this.dataSource.ExternalSystemList = data.Result;
				});
			}
		},
		externalChange: function (val) {
			this.getMemberList();
			if (val == '' || val == null) return;
			var _this = this;
			var list = _this.dataSource.ExternalSystemList;

			if (_this.isDisabled) {
				_this.dataSource.ExternalMappingList.length = 0;
				_this.$ajax.send("omsapi/sysexternalaccess/getparamlistbydef", "get", {
					id: val
				}, (data) => {
					_this.dataSource.ParamList = data.Result;
				});
				_this.$ajax.send("omsapi/sysexternalaccess/getmappinglistbydef", "get", {
					id: val
				}, (data) => {
					_this.dataSource.MapKeyList = data.Result;
				});

			}

			for (var i = 0; i < list.length; i++) {
				var system = list[i];
				if (system.ExternalSystemID == val) {
					_this.dataSource.ExternalIdentity = system.ExternalIdentity;
					_this.dataSource.ExternalSystemName = system.ExternalSystemName;
					return;
				}
			}
		},
		getOwnerList() {
			var _this = this;
			_this.dataSource.CustomerCodeList = [];
			_this.dataSource.LoadCustomerList = [];
			this.$ajax.send("omsapi/sysexternalaccess/getcustbyenterprisecode", "get", {
			}, (data) => {
				console.log(_this.dataSource.LoadCustomerList)
				_this.dataSource.LoadCustomerList = data.Result;
			});
		},
		getMemberList: function () {
			var _this = this;
			if (_this.dataSource.ExternalSystemType != 100 && _this.dataSource.ExternalSystemType != 400) {
				this.$ajax.send("omsapi/sysexternalaccess/getmemberinfobysystemType", "get", { type: _this.dataSource.ExternalSystemType }, (data) => {
					_this.dataSource.MemberId = null;
					_this.dataSource.MemberCode = null;
					_this.dataSource.MemberName = null;
					_this.dataSource.MemberList = data.Result;
				});
			}
		},
		memberChange: function (val) {
			var _this = this;
			var list = _this.dataSource.MemberList;
			for (var i = 0; i < list.length; i++) {
				var member = list[i];
				if (member.MemberId == val) {
					_this.dataSource.MemberCode = member.MemberCode;
					_this.dataSource.MemberName = member.MemberName;
					break;
				}
			}
		},
		saveExternalAccess() {
			var _this = this;
			_this.$refs["_externalAccessForm"].validate((valid) => {
				_this.isPassValidate = valid;
				if (valid) {
					if ((_this.dataSource.ExternalSystemType == 200) && (_this.dataSource
						.MemberId == null || _this.dataSource.MemberId == '')) {
						_this.Utils.messageBox("请选择仓储服务商/承运商");
						return;
					}

					if ((_this.dataSource.ExternalSystemType == 300) && (_this.dataSource
						.MemberId == null || _this.dataSource.MemberId == '')) {
						_this.dataSource.MemberId = null;
						_this.dataSource.MemberCode = null;
						_this.dataSource.MemberName = null;
					}

					if (_this.dataSource.MemberId == null || _this.dataSource.MemberId == '' || _this.dataSource.MemberId == undefined) {
						_this.dataSource.MemberId = null;
					}
					if (_this.dataSource.CustomerCodeList == undefined || _this.dataSource.CustomerCodeList.length < 1) {
						this.Utils.messageBox("请选择货主", "error");
						return;
					}
					var routeName = (this.dataSource.AccessID == null || this.dataSource.AccessID == 0) ? "create" : "edit";
					_this.$ajax.send("omsapi/sysexternalaccess/" + routeName, "post", _this.dataSource, (data) => {
						(this.dataSource.AccessID == null || this.dataSource.AccessID == 0) && (_this.dataSource.AccessID = data.Result);
						_this.$parent.syncDataSource();
						_this.Event.$emit("reloadExternalAccessPageList", data.Result);
						_this.Utils.messageBox("保存成功.", "success");
						if (_this.dataSource.MemberId == null) {
							_this.dataSource.MemberId = "";
						}
					});
				} else {
					return false;
				}
			});
		},
		addParam: function () {
			var _this = this;
			if (_this.dataSource.ExternalSystemID == null || _this.dataSource.ExternalSystemID == 0) {
				_this.Utils.messageBox("请选择系统");
				return;
			}
			var newRow = {
				ParamDesc: null,
				ParamKey: null,
				ParamValue: null
			};
			_this.dataSource.ParamList.push(newRow);
		},
		removeParam: function (row, index) {
			this.dataSource.ParamList.remove(row);
		},
		addMap: function () {
			var _this = this;
			if (_this.dataSource.ExternalSystemID == null || _this.dataSource.ExternalSystemID == 0) {
				_this.Utils.messageBox("请选择系统");
				return;
			}
			_this.$ajax.send("omsapi/sysexternalaccess/checkmappinglistbydef", "get", {
				id: _this.dataSource.ExternalSystemID
			}, (data) => {
				if (!data.IsSuccess) {
					console.log(data.IsSuccess);
					return false;
				} else {
					if (!_this.dataSource.ExternalMappingList) return;
					var newRow = {
						MappingKey: null,
						ExternalValueDesc: null,
						ExternalValue: null,
						LocalValueDesc: null,
						LocalValue: null
					};
					_this.dataSource.ExternalMappingList.push(newRow);
				}
			});
		},
		removeMap: function (row, index) {
			this.dataSource.ExternalMappingList.remove(row);
		},
	}
}
</script>

<style></style>
